import Vue from 'vue';
import VueRouter from 'vue-router';
import delayLoad from './delay';

const AccountAdminView = delayLoad(() =>
  import(/* webpackChunkName: "AccountAdminView" */ './views/AccountAdminView.vue'),
);
const AccountsAdminView = delayLoad(() =>
  import(/* webpackChunkName: "AccountsAdminView" */ './views/AccountsAdminView.vue'),
);
const Admin = delayLoad(() => import(/* webpackChunkName: "Admin" */ './views/Admin.vue'));
const ApiExpert = delayLoad(() =>
  import(/* webpackChunkName: "ApiExpert" */ './views/ApiExpert.vue'),
);
const ApiKey = delayLoad(() => import(/* webpackChunkName: "ApiKey" */ './views/ApiKey.vue'));
const App = delayLoad(() => import(/* webpackChunkName: "App" */ './views/App.vue'));
const AssertionsHeaders = delayLoad(() =>
  import(/* webpackChunkName: "AssertionsHeaders" */ './views/AssertionsHeaders.vue'),
);
const AssertionsHttp = delayLoad(() =>
  import(/* webpackChunkName: "AssertionsHttp" */ './views/AssertionsHttp.vue'),
);
const AssertionsLatency = delayLoad(() =>
  import(/* webpackChunkName: "AssertionsLatency" */ './views/AssertionsLatency.vue'),
);
const Audit = delayLoad(() => import(/* webpackChunkName: "Audit" */ './views/Audit.vue'));
const AuthHome = delayLoad(() => import(/* webpackChunkName: "AuthHome" */ './views/AuthHome.vue'));
const BillingHome = delayLoad(() =>
  import(/* webpackChunkName: "BillingHome" */ './views/BillingHome.vue'),
);
const BillingInfo = delayLoad(() =>
  import(/* webpackChunkName: "BillingInfo" */ './views/BillingInfo.vue'),
);
const Call = delayLoad(() => import(/* webpackChunkName: "Call" */ './views/Call.vue'));
const CallAdvanced = delayLoad(() =>
  import(/* webpackChunkName: "CallAdvanced" */ './views/CallAdvanced.vue'),
);
const CallAssertions = delayLoad(() =>
  import(/* webpackChunkName: "CallAssertions" */ './views/CallAssertions.vue'),
);
const CallEdit = delayLoad(() => import(/* webpackChunkName: "CallEdit" */ './views/CallEdit.vue'));
const CallPreview = delayLoad(() =>
  import(/* webpackChunkName: "CallPreview" */ './views/CallPreview.vue'),
);
const CallResult = delayLoad(() =>
  import(/* webpackChunkName: "CallResult" */ './views/CallResult.vue'),
);
const CallResults = delayLoad(() =>
  import(/* webpackChunkName: "CallResults" */ './views/CallResults.vue'),
);
const CallRun = delayLoad(() => import(/* webpackChunkName: "CallRun" */ './views/CallRun.vue'));
const CallSchedules = delayLoad(() =>
  import(/* webpackChunkName: "CallSchedules" */ './views/CallSchedules.vue'),
);
const CallWebhooks = delayLoad(() =>
  import(/* webpackChunkName: "CallWebhooks" */ './views/CallWebhooks.vue'),
);
const CallsHome = delayLoad(() =>
  import(/* webpackChunkName: "CallsHome" */ './views/CallsHome.vue'),
);
const CollectionsHome = delayLoad(() =>
  import(/* webpackChunkName: "CollectionsHome" */ './views/CollectionsHome.vue'),
);
const ConformanceResults = delayLoad(() =>
  import(/* webpackChunkName: "ConformanceResults" */ './views/ConformanceResults.vue'),
);
const ConformanceSettings = delayLoad(() =>
  import(/* webpackChunkName: "ConformanceSettings" */ './views/ConformanceSettings.vue'),
);
const ConformanceSummary = delayLoad(() =>
  import(/* webpackChunkName: "ConformanceSummary" */ './views/ConformanceSummary.vue'),
);
const Debug = delayLoad(() => import(/* webpackChunkName: "Debug" */ './views/Debug.vue'));
const FileMultipart = delayLoad(() =>
  import(/* webpackChunkName: "FileMultipart" */ './views/FileMultipart.vue'),
);
const FilesHome = delayLoad(() => import(/* webpackChunkName: "Debug" */ './views/FilesHome.vue'));
const EditApiCall = delayLoad(() =>
  import(/* webpackChunkName: "EditApiCall" */ './views/EditApiCall.vue'),
);
const GovernanceTabs = delayLoad(() =>
  import(/* webpackChunkName: "GovernanceView" */ './views/Governance.vue'),
);
const GovernanceChanges = delayLoad(() =>
  import(/* webpackChunkName: "GovernanceChanges" */ './views/GovernanceChanges.vue'),
);
const Importer = delayLoad(() => import(/* webpackChunkName: "Importer" */ './views/Importer.vue'));
const Incident = delayLoad(() => import(/* webpackChunkName: "Incident" */ './views/Incident.vue'));
const InsightsRank = delayLoad(() =>
  import(/* webpackChunkName: "InsightsRank" */ './views/InsightsRank.vue'),
);
const InsightsReport = delayLoad(() =>
  import(/* webpackChunkName: "InsightsReport" */ './views/InsightsReport.vue'),
);
const InventoryView = delayLoad(() =>
  import(/* webpackChunkName: "InventoryView" */ './views/InventoryView.vue'),
);
const Invite = delayLoad(() => import(/* webpackChunkName: "Invite" */ './views/Invite.vue'));
const Login = delayLoad(() => import(/* webpackChunkName: "Login" */ './views/Login.vue'));
const Manage = delayLoad(() => import(/* webpackChunkName: "Manage" */ './views/Manage.vue'));
const OrganizationCertificates = delayLoad(() =>
  import(/* webpackChunkName: "OrganizationCertificates" */ './views/OrganizationCertificates.vue'),
);
const OrganizationDowntimes = delayLoad(() =>
  import(/* webpackChunkName: "OrganizationDowntimes" */ './views/OrganizationDowntimes.vue'),
);
const OrganizationSettings = delayLoad(() =>
  import(/* webpackChunkName: "OrganizationSettings" */ './views/OrganizationSettings.vue'),
);
const OrganizationView = delayLoad(() =>
  import(/* webpackChunkName: "OrganizationView" */ './views/OrganizationView.vue'),
);
const OrgsAdminView = delayLoad(() =>
  import(/* webpackChunkName: "OrgsAdminView" */ './views/OrgsAdminView.vue'),
);
const Postman = delayLoad(() => import(/* webpackChunkName: "Postman" */ './views/Postman.vue'));
const Profile = delayLoad(() => import(/* webpackChunkName: "Profile" */ './views/Profile.vue'));
const Project = delayLoad(() => import(/* webpackChunkName: "Project" */ './views/Project.vue'));
const ProjectAccountsAdminView = delayLoad(() =>
  import(/* webpackChunkName: "ProjectAccountsAdminView" */ './views/ProjectAccountsAdminView.vue'),
);
const ProjectCreate = delayLoad(() =>
  import(/* webpackChunkName: "ProjectCreate" */ './views/ProjectCreate.vue'),
);
const ProjectHome = delayLoad(() =>
  import(/* webpackChunkName: "ProjectHome" */ './views/ProjectHome.vue'),
);
const ProjectPerf = delayLoad(() =>
  import(/* webpackChunkName: "ProjectPerf" */ './views/ProjectPerf.vue'),
);
const ProjectSettings = delayLoad(() =>
  import(/* webpackChunkName: "ProjectSettings" */ './views/ProjectSettings.vue'),
);
const ProjectWebhooks = delayLoad(() =>
  import(/* webpackChunkName: "ProjectWebhooks" */ './views/ProjectWebhooks.vue'),
);
const ProjectsAdminView = delayLoad(() =>
  import(/* webpackChunkName: "ProjectsAdminView" */ './views/ProjectsAdminView.vue'),
);
const Register = delayLoad(() => import(/* webpackChunkName: "Register" */ './views/Register.vue'));
const ReportStatsView = delayLoad(() =>
  import(/* webpackChunkName: "ReportStatsView" */ './views/ReportStatsView.vue'),
);
const ReportsHome = delayLoad(() =>
  import(/* webpackChunkName: "ReportsHome" */ './views/ReportsHome.vue'),
);
const Results = delayLoad(() => import(/* webpackChunkName: "Results" */ './views/Results.vue'));
const Schedules = delayLoad(() =>
  import(/* webpackChunkName: "Schedules" */ './views/Schedules.vue'),
);
const Stats = delayLoad(() => import(/* webpackChunkName: "Stats" */ './views/Stats.vue'));
const StatsView = delayLoad(() =>
  import(/* webpackChunkName: "StatsView" */ './views/StatsView.vue'),
);
const Subscriptions = delayLoad(() =>
  import(/* webpackChunkName: "Subscriptions" */ './views/Subscriptions.vue'),
);
const SupplierConfig = delayLoad(() =>
  import(/* webpackChunkName: "ApiSuppliers" */ './views/SupplierConfig.vue'),
);
const SupplierResults = delayLoad(() =>
  import(/* webpackChunkName: "ApiSuppliers" */ './views/SupplierResults.vue'),
);
const SupplierTabs = delayLoad(() =>
  import(/* webpackChunkName: "ApiSuppliers" */ './views/SupplierTabs.vue'),
);
const TokenCallback = delayLoad(() =>
  import(/* webpackChunkName: "TokenCallback" */ './views/TokenCallback.vue'),
);
const WelcomeHome = delayLoad(() =>
  import(/* webpackChunkName: "TokenCallback" */ './views/WelcomeHome.vue'),
);
const Workflow = delayLoad(() => import(/* webpackChunkName: "Workflow" */ './views/Workflow.vue'));
const WorkflowEdit = delayLoad(() =>
  import(/* webpackChunkName: "WorkflowEdit" */ './views/WorkflowEdit.vue'),
);
const WorkflowEditAdvanced = delayLoad(() =>
  import(/* webpackChunkName: "WorkflowEditAdvanced" */ './views/WorkflowEditAdvanced.vue'),
);
const WorkflowEditSchedules = delayLoad(() =>
  import(/* webpackChunkName: "WorkflowEditSchedules" */ './views/WorkflowEditSchedules.vue'),
);
const WorkflowPreview = delayLoad(() =>
  import(/* webpackChunkName: "WorkflowPreview" */ './views/WorkflowPreview.vue'),
);
const WorkflowResult = delayLoad(() =>
  import(/* webpackChunkName: "WorkflowResult" */ './views/WorkflowResult.vue'),
);
const WorkflowResults = delayLoad(() =>
  import(/* webpackChunkName: "WorkflowResults" */ './views/WorkflowResults.vue'),
);
const WorkflowsHome = delayLoad(() =>
  import(/* webpackChunkName: "WorkflowsHome" */ './views/WorkflowsHome.vue'),
);
const GettingStarted = delayLoad(() =>
  import(/* webpackChunkName: "GettingStarted" */ './views/gettingStarted/GettingStarted.vue'),
);

const projLinks = [
  {
    title: 'Project Reports',
    to: 'reports-home',
  },
  {
    title: 'Project API Calls',
    to: 'calls-home',
  },
  {
    title: 'Project Results',
    to: 'results-home',
  },
  {
    title: 'Project Authentication Settings',
    to: 'auths-home',
  },
];

const callLinks = [
  {
    title: 'This API Call',
    to: 'calls-preview',
  },
  {
    title: 'All Results for this API Call',
    to: 'calls-results',
  },
  // { title: 'This API Call\'s Authentication', nonVue: 'services_view' },
  // { title: 'All API Calls using this Authentication', nonVue: 'tests_list' },
  {},
  {
    title: 'Stats overview',
    to: 'stats-view',
  },
  {
    title: 'Latency over time',
    to: 'stats-graph-latency',
  },
  {
    title: 'Pass/Fail over time',
    to: 'stats-graph-passfail',
  },
  {
    title: 'Pass Latency histogram',
    to: 'stats-graph-latency-histo',
  },
  {},
  ...projLinks,
];

const workflowLinks = [
  {
    title: 'This Workflow',
    to: 'workflows-preview',
  },
  {
    title: 'All Results for this Workflow',
    to: 'workflows-results',
  },
  {},
  ...projLinks,
];

const routes = [
  {
    path: '/',
    // name: 'APImetrics',
    component: App,
    meta: {
      title: '',
      icon: '',
      defaultChild: 'collections-home',
    },
    children: [
      {
        name: 'login',
        path: '',
        component: Login,
        meta: {
          title: 'APImetrics',
          icon: 'glyphicon glyphicon-ok',
          modalView: 'auto',
          noAuthOk: true,
          noProjectOk: true,
        },
      },
      {
        name: 'debug',
        path: 'debug/',
        component: Debug,
        meta: {
          title: 'Debug',
          icon: 'glyphicon glyphicon-info-sign',
          noAuthOk: true,
          noProjectOk: true,
        },
      },
      {
        name: 'incident-report',
        path: 'incident/:incidentId',
        component: Incident,
        meta: {
          title: 'Incident',
          icon: 'glyphicon glyphicon-exclamation-sign',
          noAuthOk: true,
          noProjectOk: true,
        },
      },
      {
        name: 'ext-href',
        path: 'ext/:href',
        beforeEnter(to, from, next) {
          window.location.href = to.params.href;
          next();
        },
      },
      {
        name: 'old-login',
        path: 'login/',
        redirect: { name: 'login' },
      },
      {
        name: 'logout',
        path: 'logout/',
        component: Login,
        meta: {
          title: 'APImetrics',
          icon: 'glyphicon glyphicon-ok',
          modalView: 'auto',
          noAuthOk: true,
          noProjectOk: true,
        },
      },
      {
        name: 'register',
        path: 'register/',
        component: Register,
        meta: {
          title: 'APImetrics',
          icon: 'glyphicon glyphicon-ok',
          modalView: 'auto',
          noAuthOk: true,
          noProjectOk: true,
        },
      },
      {
        name: 'invites-view',
        path: 'invite/:inviteId/:inviteSecret/',
        component: Invite,
        meta: {
          title: 'Invite',
          icon: 'glyphicon glyphicon-ok',
          noAuthOk: true,
        },
      },
      {
        path: 'account-settings/',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          title: 'Project Settings',
          icon: 'glyphicon glyphicon-cog',
        },
        children: [
          {
            name: 'account-settings-home',
            path: '',
            redirect: { name: 'assertions-latency' },
          },
          {
            name: 'account-settings-http',
            path: 'http',
            redirect: { name: 'assertions-http' },
          },
          {
            name: 'account-settings-conditions',
            path: 'conditions',
            redirect: { name: 'assertions-headers' },
          },
          {
            name: 'account-settings-webhooks',
            path: 'webhooks',
            redirect: { name: 'webhooks-home' },
          },
        ],
      },
      {
        name: 'audits-view',
        path: 'audits/',
        component: Audit,
        meta: {
          title: 'Audit Logs',
          icon: 'glyphicon glyphicon-check',
          description: 'Track changes made to this project.',
        },
      },
      {
        name: 'audits-view-org',
        path: 'audits/organization/:orgId/',
        component: Audit,
        meta: {
          title: 'Organization Audit Logs',
          icon: 'glyphicon glyphicon-check',
          description: 'Track changes made to this organization.',
        },
      },
      {
        name: 'audits-view-resource',
        path: 'audits/resource/:resourceId/',
        component: Audit,
        meta: {
          title: 'Resource Audit Logs',
          icon: 'glyphicon glyphicon-check',
          description: 'Track changes made to this resource.',
        },
      },
      {
        path: 'assertions/',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          title: 'Project Assertions',
          icon: 'glyphicon glyphicon-wrench',
          defaultChild: 'assertions-latency',
        },
        children: [
          {
            name: 'assertions-latency',
            path: 'latency',
            component: AssertionsLatency,
            meta: {
              title: 'Project Latency checks',
              icon: 'glyphicon glyphicon-time',
            },
          },
          {
            name: 'assertions-http',
            path: 'http',
            component: AssertionsHttp,
            meta: {
              title: 'Project HTTP status checks',
              icon: 'glyphicon glyphicon-transfer',
            },
          },
          {
            name: 'assertions-headers',
            path: 'headers',
            component: AssertionsHeaders,
            meta: {
              title: 'Project Header checks',
              icon: 'glyphicon glyphicon-cog',
            },
          },
        ],
      },
      {
        path: 'runs/',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          title: 'Workflows',
          icon: 'glyphicon glyphicon-tasks',
          defaultChild: 'workflows-home',
        },
        children: [
          {
            name: 'workflows-home',
            path: '',
            component: WorkflowsHome,
            meta: {
              title: '',
              breadcrumb: '',
              icon: '',
              description: 'List of all workflows in this project.',
              links: projLinks,
            },
          },
          {
            name: 'workflow-result',
            path: 'result/:workflowResultId/',
            component: WorkflowResult,
            meta: {
              title: 'Workflow Result',
              icon: 'glyphicon glyphicon-list',
              dropdown: 'workflows',
              description: 'Result of an attempted run of a workflow.',
              links: projLinks,
            },
          },
          {
            path: 'run/:workflowId/',
            component: Workflow,
            meta: {
              title: 'Workflow',
              icon: 'glyphicon glyphicon-tasks',
              defaultChild: 'workflows-preview',
              dropdown: 'workflows',
            },
            children: [
              {
                name: 'workflows-preview',
                path: '',
                component: WorkflowPreview,
                meta: {
                  title: 'Summary',
                  description: 'View the sequence of API Calls that will be monitored.',
                  icon: 'glyphicon glyphicon-play',
                  links: workflowLinks,
                },
              },
              {
                name: 'workflows-edit',
                path: 'edit',
                component: WorkflowEdit,
                meta: {
                  title: 'Edit',
                  description: 'Edit the API calls made by this Workflow.',
                  icon: 'glyphicon glyphicon-cog',
                  links: workflowLinks,
                },
              },
              {
                name: 'workflows-edit-advanced',
                path: 'advanced',
                component: WorkflowEditAdvanced,
                meta: {
                  title: 'Advanced',
                  description: 'Manage meta-data and advanced features for this Workflow.',
                  icon: 'glyphicon glyphicon-tags',
                  links: workflowLinks,
                },
              },
              {
                name: 'workflows-results',
                path: 'results',
                component: WorkflowResults,
                meta: {
                  title: 'Results',
                  icon: 'glyphicon glyphicon-list',
                  description: 'View the results of this Workflow.',
                  links: workflowLinks,
                },
              },
              {
                name: 'workflows-schedules',
                path: 'schedules',
                component: WorkflowEditSchedules,
                meta: {
                  title: 'Monitoring',
                  icon: 'glyphicon glyphicon-time',
                  description:
                    'Choose how often this Workflow is called and from which locations around the world.',
                  links: workflowLinks,
                },
              },
              {
                name: 'workflows-audit',
                path: 'audit',
                component: Audit,
                meta: {
                  title: 'Audit Logs',
                  icon: 'glyphicon glyphicon-ok',
                  description: 'Track changes made to this Workflow.',
                  links: workflowLinks,
                },
              },
            ],
          },
        ],
      },
      {
        path: 'calls/',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          title: 'API Calls',
          icon: 'glyphicon glyphicon-list',
          defaultChild: 'calls-home',
        },
        children: [
          {
            name: 'calls-edit-new',
            path: 'edit/:callId',
            component: EditApiCall,
            meta: {
              title: 'Edit API Call',
              icon: 'glyphicon glyphicon-list',
            },
          },
        ],
      },
      {
        path: 'tests/',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          title: 'API Calls',
          icon: 'glyphicon glyphicon-list',
          defaultChild: 'calls-home',
        },
        children: [
          {
            name: 'calls-home',
            path: '',
            component: CallsHome,
            meta: {
              title: 'API Calls',
              icon: 'glyphicon glyphicon-list',
              breadSkip: true,
              description: 'List of all API Calls defined in this project.',
              links: projLinks,
            },
          },
          {
            path: 'test/:callId/',
            component: Call,
            meta: {
              title: 'API Call',
              icon: 'fa fa-exchange',
              dropdown: 'calls',
            },
            children: [
              {
                name: 'calls-preview',
                path: '',
                component: CallPreview,
                meta: {
                  title: 'Summary',
                  description: 'View the API request that will be monitored.',
                  icon: 'glyphicon glyphicon-play',
                  links: callLinks,
                  // noAuthOk: true,
                },
              },
              {
                name: 'calls-run',
                path: 'run/:locationId',
                component: CallRun,
                meta: {
                  title: 'Run',
                  icon: 'glyphicon glyphicon-play',
                  links: callLinks,
                },
              },
              {
                name: 'calls-edit',
                path: 'edit',
                component: CallEdit,
                meta: {
                  title: 'API Call Edit',
                  breadcrumb: 'Edit',
                  description: 'Set up the API request that will be monitored.',
                  icon: 'glyphicon glyphicon-cog',
                  links: callLinks,
                  sidebar: true,
                },
              },
              {
                name: 'calls-advanced',
                path: 'advanced',
                component: CallAdvanced,
                meta: {
                  title: 'API Call Advanced',
                  breadcrumb: 'Advanced',
                  description: 'Manage meta-data and advanced features for this API call.',
                  icon: 'glyphicon glyphicon-tag',
                  links: callLinks,
                  sidebar: true,
                },
              },
              {
                name: 'calls-meta',
                path: 'meta',
                redirect: { name: 'calls-advanced' },
              },
              {
                name: 'calls-sharing',
                path: 'sharing',
                redirect: { name: 'calls-advanced' },
              },
              {
                name: 'calls-schedules',
                path: 'schedules',
                component: CallSchedules,
                meta: {
                  title: 'API Call Monitoring',
                  breadcrumb: 'Monitoring',
                  icon: 'glyphicon glyphicon-time',
                  description:
                    'Choose how often this API is called and from which locations around the world.',
                  links: callLinks,
                },
              },
              {
                name: 'calls-conditions',
                path: 'conditions',
                redirect: { name: 'calls-assertions' },
              },
              {
                name: 'calls-assertions',
                path: 'assertions',
                component: CallAssertions,
                meta: {
                  title: 'Assertions',
                  icon: 'glyphicon glyphicon-pencil',
                  description: 'Add checks that are run against an API response.',
                  links: callLinks,
                },
              },
              {
                name: 'calls-insights',
                path: 'insights',
                component: InsightsReport,
                meta: {
                  title: 'Insights Report',
                  breadcrumb: 'Report',
                  icon: 'glyphicon glyphicon-hourglass',
                  description: 'In-depth analysis for an API Call for the time period.',
                  links: callLinks,
                },
              },
              {
                name: 'calls-insights-date',
                path: 'insights/:start/:end/',
                component: InsightsReport,
                meta: {
                  title: 'Insights Report',
                  breadcrumb: 'Report',
                  icon: 'glyphicon glyphicon-hourglass',
                  description: 'In-depth analysis for an API Call for the time period.',
                  links: callLinks,
                },
              },
              {
                path: 'stats/',
                component: Stats,
                meta: {
                  title: 'Stats',
                  icon: 'glyphicon glyphicon-signal',
                  defaultChild: 'stats-view',
                },
                children: [
                  {
                    name: 'stats-view',
                    path: '',
                    component: StatsView,
                    meta: {
                      title: 'Overview',
                      icon: 'glyphicon glyphicon-signal',
                      description: 'A deeper look at the performance of this API Call.',
                      links: callLinks,
                    },
                  },
                  {
                    name: 'stats-graph-latency',
                    path: 'latency',
                    component: StatsView,
                    meta: {
                      title: 'Latency',
                      icon: 'glyphicon glyphicon-signal',
                      links: callLinks,
                    },
                  },
                  {
                    name: 'stats-graph-latency-histo',
                    path: 'histogram',
                    component: StatsView,
                    meta: {
                      title: 'Latency Histogram',
                      icon: 'glyphicon glyphicon-signal',
                      links: callLinks,
                    },
                  },
                  {
                    name: 'stats-graph-response-size',
                    path: 'response',
                    component: StatsView,
                    meta: {
                      title: 'Response Size',
                      icon: 'glyphicon glyphicon-signal',
                      links: callLinks,
                    },
                  },
                  {
                    name: 'stats-latency-cumulative-heatmap',
                    path: 'heatmap',
                    redirect: { name: 'stats-latency-sequential-heatmap' },
                  },
                  {
                    name: 'stats-latency-sequential-heatmap',
                    path: 'heatmap',
                    component: StatsView,
                    meta: {
                      title: 'Latency Heatmap',
                      icon: 'glyphicon glyphicon-signal',
                      links: callLinks,
                    },
                  },
                  {
                    name: 'stats-graph-passfail-graph',
                    path: 'passfail-block',
                    redirect: { name: 'stats-graph-passfail' },
                  },
                  {
                    name: 'stats-graph-passfail',
                    path: 'passfail',
                    component: StatsView,
                    meta: {
                      title: 'Pass / Fail block graph',
                      icon: 'glyphicon glyphicon-signal',
                      links: callLinks,
                    },
                  },
                  {
                    name: 'stats-graph-map',
                    path: 'map',
                    component: StatsView,
                    meta: {
                      title: 'Latency Map',
                      icon: 'glyphicon glyphicon-signal',
                      links: callLinks,
                    },
                  },
                ],
              },
              {
                name: 'calls-audit',
                path: 'audit',
                component: Audit,
                meta: {
                  title: 'Audit Logs',
                  description: 'Track the changes made to the API call over time.',
                  icon: 'glyphicon glyphicon-ok',
                  links: callLinks,
                },
              },
              {
                name: 'calls-results',
                path: 'results',
                component: CallResults,
                meta: {
                  title: 'Call Results',
                  breadcrumb: 'Results',
                  description: 'We store every request and response of every API call we make.',
                  icon: 'glyphicon glyphicon-check',
                  links: callLinks,
                },
              },
              {
                name: 'calls-webhooks',
                path: 'webhooks',
                component: CallWebhooks,
                meta: {
                  title: 'Alerts & Webhooks',
                  description:
                    'Alerts are triggered after each API, depending on whether ' +
                    'the API resulted in a PASS, SLOW, WARNING, or FAIL, ' +
                    'or any combination of those options.',
                  icon: 'glyphicon glyphicon-globe',
                  links: callLinks,
                },
              },
            ],
          },
          {
            name: 'calls-result',
            path: 'result/:resultId/',
            component: CallResult,
            meta: {
              title: 'Result',
              icon: 'glyphicon glyphicon-check',
              description: 'Request and Response from an API call.',
              links: projLinks,
            },
          },
          {
            path: 'stats/:callId/',
            component: Stats,
            meta: {
              title: 'API Call',
              icon: 'fa fa-exchange',
            },
            children: [
              {
                name: 'old-stats-view',
                path: '',
                redirect: { name: 'stats-view' },
              },
              {
                name: 'old-stats-graph-latency',
                path: 'latency',
                redirect: { name: 'stats-graph-latency' },
              },
              {
                name: 'old-stats-graph-latency-histo',
                path: 'histogram',
                redirect: { name: 'stats-graph-latency-histo' },
              },
              {
                name: 'old-stats-graph-response-size',
                path: 'response',
                redirect: { name: 'stats-graph-response-size' },
              },
              {
                name: 'old-stats-latency-cumulative-heatmap',
                path: 'heatmap',
                redirect: { name: 'stats-latency-sequential-heatmap' },
              },
              {
                name: 'old-stats-latency-sequential-heatmap',
                path: 'heatmap',
                redirect: { name: 'stats-latency-sequential-heatmap' },
              },
              {
                name: 'old-stats-graph-passfail-graph',
                path: 'passfail-block',
                redirect: { name: 'stats-graph-passfail' },
              },
              {
                name: 'old-stats-graph-passfail',
                path: 'passfail',
                redirect: { name: 'stats-graph-passfail' },
              },
              {
                name: 'old-stats-graph-map',
                path: 'map',
                redirect: { name: 'stats-graph-map' },
              },
            ],
          },
          {
            name: 'results-home',
            path: 'results',
            component: Results,
            meta: {
              title: 'Results',
              description: 'We store every request and response of every API call we make.',
              icon: 'glyphicon glyphicon-check',
            },
          },
        ],
      },
      {
        path: 'files/',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          title: 'Files',
          icon: 'glyphicon glyphicon-upload',
          defaultChild: 'files-home',
        },
        children: [
          {
            name: 'files-home',
            path: '',
            component: FilesHome,
            meta: {
              title: 'Files',
              icon: 'glyphicon glyphicon-upload',
            },
          },
          {
            name: 'file-create',
            path: 'create',
            component: FileMultipart,
            meta: {
              title: 'File Upload',
              icon: 'glyphicon glyphicon-upload',
            },
          },
          {
            name: 'files-edit',
            path: 'file/:fileId/',
            component: FileMultipart,
            meta: {
              title: 'File Edit',
              icon: 'glyphicon glyphicon-upload',
            },
          },
        ],
      },
      {
        name: 'importer',
        path: 'importer/',
        component: Importer,
        meta: {
          title: 'Import',
          icon: 'fa fa-plus-circle',
        },
      },
      {
        path: 'insights/',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          title: 'Insights',
          icon: 'glyphicon glyphicon-hourglass',
          defaultChild: 'insights',
        },
        children: [
          {
            name: 'insights',
            path: '',
            component: InsightsRank,
            meta: {
              title: 'CASC scores',
              icon: 'fa fa-list-ol',
              description:
                'Compare the performance of API Calls in the project for the time period.',
            },
          },
          {
            name: 'insights-report',
            path: ':insightsId/',
            // component: InsightsReport,
            redirect: (to) => {
              const [callId, start, end] = to.params.insightsId.split('__');
              return {
                name: 'calls-insights-date',
                params: {
                  callId,
                  start,
                  end,
                },
              };
            },
            // meta: {
            //   title: 'Insights Report',
            //   breadcrumb: 'Report',
            //   icon: 'fa fa-calendar-check-o',
            //   description: 'In-depth analysis for an API Call for the time period.',
            // },
          },
        ],
      },
      {
        name: 'old-insights-rank',
        path: 'rank/',
        redirect: { name: 'insights' },
      },
      {
        name: 'old-insights-rank-graph',
        path: 'rank/graph/history',
        redirect: { name: 'insights' },
      },
      {
        name: 'postman',
        path: 'postman/',
        component: Postman,
        meta: {
          title: 'Postman Monitors',
          description:
            'Sync and monitor your API Calls with your Postman Workspace. Postman subscription required.',
        },
      },
      {
        name: 'project',
        path: 'project',
        component: ProjectCreate,
        meta: {
          title: 'New Project',
          icon: 'fa fa-folder',
          noProjectOk: true,
        },
      },
      {
        path: '/governance',
        component: GovernanceTabs,
        children: [
          {
            name: 'conformance-summary',
            path: 'conformance/summary',
            component: ConformanceSummary,
            meta: {
              title: 'Conformance Summary [beta]',
              breadcrumb: '',
              icon: 'fa fa-list-alt',
            },
          },
          {
            name: 'conformance-results',
            path: 'conformance/results',
            component: ConformanceResults,
            meta: {
              title: 'Issue Details [beta]',
              breadcrumb: '',
              icon: 'fa fa-folder-open',
            },
          },
          {
            name: 'conformance-settings',
            path: 'conformance/settings',
            component: ConformanceSettings,
            meta: {
              title: 'Conformance Settings [beta]',
              breadcrumb: '',
              icon: 'glyphicon glyphicon-cog',
            },
          },
          {
            name: 'governance-changes',
            path: 'changes',
            component: GovernanceChanges,
            meta: {
              title: 'Conformance Changes [beta]',
              breadcrumb: '',
              icon: 'glyphicon glyphicon-transfer',
            },
          },
        ],
      },
      {
        path: '/suppliers',
        component: SupplierTabs,
        children: [
          {
            name: 'supplier-config',
            path: 'config',
            component: SupplierConfig,
            meta: {
              title: 'Suppliers',
              icon: 'glyphicon glyphicon-signal',
            },
          },
          {
            name: 'supplier-results',
            path: 'results',
            component: SupplierResults,
            meta: {
              title: 'Suppliers',
              icon: 'glyphicon glyphicon-signal',
            },
          },
        ],
      },
      {
        name: 'inventory',
        path: 'inventory',
        component: InventoryView,
        meta: {
          title: 'Inventory [beta]',
          description:
            'Inventory indexes observed traffic from live sources and OpenAPI Specification files to make it easier for you to configure your endpoint monitoring.',
          icon: 'glyphicon glyphicon-book',
        },
      },
      {
        name: 'reports-home',
        path: 'reports/',
        component: ReportsHome,
        meta: {
          title: 'Reports',
          icon: 'glyphicon glyphicon-list-alt',
        },
      },
      {
        name: 'reports-graph-latency',
        path: 'reports/:reportId/:groupId/latency',
        component: ReportStatsView,
        meta: {
          title: 'Report Latency',
          icon: 'glyphicon glyphicon-cog',
        },
      },
      {
        name: 'reports-graph-latency-graph',
        path: 'reports/:reportId/:groupId/:graphType',
        component: ReportStatsView,
        meta: {
          title: 'Report Graph',
          icon: 'glyphicon glyphicon-cog',
        },
      },
      {
        name: 'auths-home',
        path: 'services/',
        component: AuthHome,
        meta: {
          title: 'Auth Settings',
          icon: 'glyphicon glyphicon-lock',
        },
      },
      {
        path: 'settings/',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          title: 'Settings',
          icon: 'glyphicon glyphicon-cog',
          defaultChild: 'settings-profile',
        },
        children: [
          {
            name: 'settings-apikey',
            path: 'api-key',
            component: ApiKey,
            meta: {
              title: 'APImetrics API key',
              icon: 'glyphicon glyphicon-info-sign',
              description: 'Manage API keys used to access our APImetrics API.',
            },
          },
          {
            name: 'settings-organization-project',
            path: 'organization/',
            component: OrganizationSettings,
            meta: {
              title: 'Organization',
              icon: 'fa fa-users',
              description: 'Manage the access to the projects in this Organization.',
              noProjectOk: true,
            },
          },
          {
            name: 'settings-organization',
            path: 'organization/:orgId/',
            redirect: { name: 'organization-settings' },
          },
          {
            name: 'settings-profile',
            path: 'profile',
            component: Profile,
            meta: {
              title: 'Profile',
              icon: 'glyphicon glyphicon-info-sign',
              description: 'Manage your account.',
              noProjectOk: true,
            },
          },
          {
            name: 'settings-project',
            path: 'project',
            component: ProjectSettings,
            meta: {
              title: 'Project',
              icon: 'fa fa-folder',
              description: 'Manage access to this project.',
            },
          },
        ],
      },
      {
        name: 'plain-subscriptions',
        path: 'subscriptions',
        redirect: { name: 'email-subscriptions' },
      },
      {
        name: 'email-subscriptions',
        path: 'subscriptions/email',
        component: Subscriptions,
        meta: {
          title: 'Email Subscriptions',
          icon: 'glyphicon glyphicon-envelope',
          description: 'Manage your subscriptions to email alerts and updates.',
          noProjectOk: true,
        },
      },
      {
        name: 'api-expert',
        path: 'subscriptions/api-expert',
        component: ApiExpert,
        meta: {
          title: 'API Expert Subscriptions',
          icon: 'glyphicon glyphicon-credit-card',
          description: 'Manage your subscriptions to API.expert collections.',
          noProjectOk: true,
        },
      },
      {
        name: 'token-callback-noslash',
        path: 'tokens/callback',
        component: TokenCallback,
        meta: {
          title: 'OAuth Callback Handler',
          icon: 'glyphicon glyphicon-lock',
          description: 'Choose how to handle callbacks from auth flows.',
        },
      },
      {
        name: 'token-callback',
        path: 'tokens/callback/',
        component: TokenCallback,
        meta: {
          title: 'OAuth Callback Handler',
          icon: 'glyphicon glyphicon-lock',
          description: 'Choose how to handle callbacks from auth flows.',
        },
      },
      {
        name: 'manage',
        path: 'manage',
        component: Manage,
        meta: {
          title: 'Manage Resources',
          icon: 'glyphicon glyphicon-cog',
          description: 'Copy or move API calls, Authentication, Workflows etc between projects.',
        },
        props: ({ query: { type, call, auth, workflow, report, copy } }) => ({
          type,
          call,
          auth,
          workflow,
          report,
          copy: copy && copy.toLowerCase() === 'true',
        }),
      },
      {
        name: 'schedules-home',
        path: 'schedules',
        component: Schedules,
        meta: {
          title: 'Schedules',
          icon: 'glyphicon glyphicon-time',
          description: 'Monitor your APIs and Workflows by adding them to a schedule.',
        },
      },
      {
        path: 'home/',
        component: Project,
        meta: {
          title: '',
          breadcrumb: '',
          icon: '',
        },
        children: [
          {
            name: 'project-home',
            path: '',
            component: ProjectHome,
            meta: {
              title: '',
              breadcrumb: '',
              icon: '',
              description:
                'Projects contain API Calls and their associated authentication settings and environment.',
            },
          },
          {
            name: 'project-perf',
            path: 'performance',
            component: ProjectPerf,
            meta: {
              title: 'Performance',
              breadcrumb: '',
              icon: 'glyphicon glyphicon-dashboard',
              description: 'View daily, weekly and monthly performance overview.',
            },
          },
          {
            name: 'project-settings',
            path: 'settings',
            component: ProjectSettings,
            meta: {
              title: 'Settings',
              icon: 'glyphicon glyphicon-cog',
            },
          },
          {
            name: 'project-audit',
            path: 'audit',
            component: Audit,
            meta: {
              title: 'Audit Logs',
              icon: 'glyphicon glyphicon-ok',
              description: 'Audit logs track changes made to resources inside this project.',
            },
          },
        ],
      },
      {
        name: 'projects-home',
        path: 'projects',
        redirect: { name: 'collections-home' },
      },
      {
        name: 'collections-home',
        path: 'collections',
        component: CollectionsHome,
        meta: {
          title: 'Collections',
          icon: 'glyphicon glyphicon-home',
          noProjectOk: true,
          breadSkip: true,
          description:
            'Full summary of the collections you are subscribed to and your personal monitoring projects.',
        },
      },
      {
        path: 'billing/',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            name: 'billing-home',
            path: '',
            component: BillingHome,
            meta: {
              title: 'Subscriptions',
              icon: 'glyphicon glyphicon-book',
              noProjectOk: true,
              description: 'Manage your subscriptions.',
            },
          },
          {
            name: 'billing-info',
            path: ':subId/',
            component: BillingInfo,
            meta: {
              title: 'Billing info',
              icon: 'glyphicon glyphicon-book',
              description: 'Manage your subscription.',
            },
          },
        ],
      },
      {
        path: 'organization/:orgId/',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            name: 'organization-home',
            path: '',
            component: OrganizationView,
            meta: {
              title: 'Collection Overview',
              breadcrumb: 'Overview',
              icon: 'fa fa-users',
              description: 'Overview of the projects in this collection.',
              noProjectOk: true,
            },
          },
          {
            name: 'organization-settings',
            path: 'settings',
            component: OrganizationSettings,
            meta: {
              title: 'Collection Settings',
              breadcrumb: 'Settings',
              icon: 'glyphicon glyphicon-cog',
              noProjectOk: true,
            },
          },
          {
            name: 'organization-downtimes',
            path: 'downtimes',
            component: OrganizationDowntimes,
            meta: {
              title: 'Organization Downtimes',
              breadcrumb: 'Downtimes',
              icon: 'glyphicon glyphicon-off',
              noProjectOk: true,
            },
          },
          {
            name: 'organization-certificates',
            path: 'certificates',
            component: OrganizationCertificates,
            meta: {
              title: 'Organization Certificates',
              breadcrumb: 'Settings',
              icon: 'glyphicon glyphicon-cog',
              noProjectOk: true,
            },
          },
        ],
      },
      {
        name: 'webhooks-home',
        path: 'webhooks',
        component: ProjectWebhooks,
        meta: {
          title: 'Alerts & Webhooks',
          description:
            'Alerts are triggered after each API, depending on whether ' +
            'the API resulted in a PASS, SLOW, WARNING, or FAIL, ' +
            'or any combination of those options.',
          icon: 'glyphicon glyphicon-globe',
        },
      },
      {
        path: 'admin/',
        component: Admin,
        meta: {
          title: 'Admin',
          icon: 'glyphicon glyphicon-tower',
          description: 'APImetrics admins only!',
          noProjectOk: true,
          siteAdminOnly: true,
        },
        children: [
          {
            name: 'accounts-admin-home',
            path: 'accounts',
            component: AccountsAdminView,
            meta: {
              title: 'Accounts',
              description: 'APImetrics admins can manage Accounts here',
              icon: 'fa fa-users',
              noProjectOk: true,
              siteAdminOnly: true,
            },
          },
          {
            name: 'account-admin-home',
            path: 'accounts/:accountId',
            component: AccountAdminView,
            meta: {
              title: 'Account',
              description: 'APImetrics admins can manage an Account here',
              icon: 'fa fa-user',
              noProjectOk: true,
              siteAdminOnly: true,
            },
          },
          {
            name: 'orgs-admin-home',
            path: 'organizations',
            component: OrgsAdminView,
            meta: {
              title: 'Organizations',
              description: 'APImetrics admins can manage Organizations / Collections here',
              icon: 'fa fa-building-o',
              noProjectOk: true,
              siteAdminOnly: true,
            },
          },
          {
            name: 'projects-admin-home',
            path: 'organizations/:orgId',
            component: ProjectsAdminView,
            meta: {
              title: 'Projects',
              description:
                'APImetrics admins can manage projects in an Organization / Collection here',
              icon: 'fa fa-folder',
              noProjectOk: true,
              siteAdminOnly: true,
            },
          },
          {
            name: 'projects-accounts-admin-home',
            path: 'projects/:projectId',
            component: ProjectAccountsAdminView,
            meta: {
              title: 'Project Accounts',
              description: 'APImetrics admins can see who has access to this project here',
              icon: 'fa fa-users',
              noProjectOk: true,
              siteAdminOnly: true,
            },
          },
        ],
      },
      {
        name: 'welcome-home',
        path: 'welcome',
        component: WelcomeHome,
        meta: {
          title: 'Welcome to APImetrics',
          icon: 'glyphicon glyphicon-home',
          noProjectOk: true,
          modalView: 'auto',
        },
      },
      {
        name: 'getting-started',
        path: 'getting-started',
        component: GettingStarted,
        meta: {
          noProjectOk: true,
          modalView: 'full',
          steps: [
            { title: 'Step 1 - My info' },
            { title: 'Step 2 - Project details' },
            { title: 'Step 3 - Monitor your own API' },
            { title: 'Step 4 - Install sample API calls' },
            { title: 'Step 5 - Summary' },
          ],
        },
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'collections-home' },
    name: 'catch-all',
  },
];

Vue.use(VueRouter);

const TOPBAR_HEIGHT = 70;

function findTop(elem) {
  let curtop = elem.offsetTop;
  let curElem = elem.offsetParent;
  while (curElem) {
    curtop += curElem.offsetTop;
    curElem = curElem.offsetParent;
  }
  return curtop;
}

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      try {
        const el = document.querySelector(to.hash);

        if (el) {
          const top = findTop(el) - TOPBAR_HEIGHT;
          const scrollTo = {
            x: 0,
            y: top,
            behavior: 'smooth',
          };

          if (to.name !== from.name) {
            return new Promise((resolve) => {
              setTimeout(() => resolve(scrollTo), 500);
            });
          }
          return scrollTo;
        }
      } catch (e) {
        // Ignore - sometimes the hash isn't a querySelector
      }
    }
    if (savedPosition) {
      return savedPosition;
    }
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
    ? `${process.env.VUE_APP_TITLE}: ${to.meta.title}`
    : process.env.VUE_APP_TITLE;
  // eslint-disable-next-line no-underscore-dangle, no-multi-assign
  const hsq = (window._hsq = window._hsq || []);
  if (hsq) {
    hsq.push(['setPath', to.path]);
    hsq.push(['trackPageView']);
  }
  next();
});

router.onError((error) => {
  console.log(error);
  if (/loading chunk [A-Za-z0-9_-]+ failed./i.test(error.message) && navigator.onLine) {
    window.location.reload();
  }
});

export default router;
